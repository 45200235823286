import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  onSnapshot,
  doc
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset
} from "firebase/auth";
import { getPerformance } from "firebase/performance";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAVoFOjc2Dco7ibmXjK4jyiq9BTjaGFT48",
  authDomain: "morris-real-estate-solutions.firebaseapp.com",
  projectId: "morris-real-estate-solutions",
  storageBucket: "morris-real-estate-solutions.appspot.com",
  messagingSenderId: "668405164409",
  appId: "1:668405164409:web:3e352e23da91af915767a8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();

export const performanceMonitor = getPerformance();
export const MAX_ATTRIBUTE_VALUE_LENGTH = 40;
export const analytics = getAnalytics(app);
export const logAnalyticsEvent = (eventName, eventParams) =>
  logEvent(analytics, eventName, eventParams);

export const CLOUD_MESSAGING_IDENTITY_KEY = "";

export let messaging, requestNotificationPermission;

export const auth = getAuth(app);

const storage = getStorage(app);

export const signUp = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);
export const signIn = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const sendPasswordEmail = emailAddress =>
  sendPasswordResetEmail(auth, emailAddress);
export const verifyPasswordCode = actionCode =>
  verifyPasswordResetCode(auth, actionCode);
export const handlePasswordReset = (actionCode, newPassword) =>
  confirmPasswordReset(auth, actionCode, newPassword);

// const firestore = getFirestore();
// firestore.enablePersistence();
export const postFeedback = feedback => {};
// collection("/feedback").add(feedback);
export const getUserDonations = uid => {};
// collection("/donations")
//   .where("firebase_uid", "==", uid)
//   .orderBy("created", "desc");
export const getCampaignDonations = (campaignId, limit) => {};
// collection("/donations")
//   .where("campaign_id", "==", campaignId)
//   .orderBy("created", "desc")
//   .limit(limit);
export const campaignSubscription = () => {}; // collection("/campaign");

export const unsubFromEntitySubscription = (
  entityPath,
  currentEntityDataCallback
) => {
  const entityCollection = collection(db, entityPath);
  return onSnapshot(entityCollection, currentEntityDataCallback);
};

export const createEntity = async (entityPath, entityPayload) => {
  try {
    if (
      Array.isArray(entityPayload.images) &&
      entityPayload.images.length > 0
    ) {
      const { images, ...addDocPayload } = entityPayload;
      const file = images[0];
      const storageRef = ref(
        storage,
        `${entityPath}/${Date.now()}${file.name}`
      );
      const metadata = {
        type: file.type,
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        createdAt: Date.now()
      };
      const snapshot = await uploadBytes(storageRef, file, metadata);
      const downloadUrl = await getDownloadURL(
        ref(storage, snapshot.ref.fullPath)
      );
      return await addDoc(collection(db, entityPath), {
        ...addDocPayload,
        imageSrc: downloadUrl,
        fullPath: snapshot.ref.fullPath
      });
    } else {
      return await addDoc(collection(db, entityPath), entityPayload);
    }
  } catch (e) {
    return e;
  }
};

export const updateEntity = async (entityPath, entityId, entityPayload) => {
  try {
    if (
      Array.isArray(entityPayload.images) &&
      entityPayload.images.length > 0
    ) {
      const { images, ...addDocPayload } = entityPayload;
      const file = images[0];
      const storageRef = ref(
        storage,
        `${entityPath}/${Date.now()}${file.name}`
      );
      const metadata = {
        type: file.type,
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        createdAt: Date.now()
      };

      if (addDocPayload.fullPath) {
        await deleteObject(ref(storage, addDocPayload.fullPath));
      }

      const snapshot = await uploadBytes(storageRef, file, metadata);
      const downloadUrl = await getDownloadURL(
        ref(storage, snapshot.ref.fullPath)
      );
      return await updateDoc(doc(db, entityPath, entityId), {
        ...addDocPayload,
        imageSrc: downloadUrl,
        fullPath: snapshot.ref.fullPath
      });
    } else {
      return await updateDoc(doc(db, entityPath, entityId), entityPayload);
    }
  } catch (e) {
    return e;
  }
};

export const deleteEntity = async (entityPath, entityId, imgPath) => {
  const ref = doc(db, entityPath, entityId);
  if (imgPath) {
    await deleteObject(ref(storage, imgPath));
  }

  await deleteDoc(doc(db, entityPath, entityId));
};

export default {};
