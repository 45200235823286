import React, { useState, useEffect, useContext} from 'react';
import Slider from "react-slick";
import { createEntity, deleteEntity, unsubFromEntitySubscription, updateEntity } from '../../firebase';
import { DIALOG } from '../../utils/constants';
import { DialogContext } from '../dialog';
import EmployeeForm from '../forms/EmployeeForm'
import PropertiesForLeaseForm from '../forms/PropertiesForLeaseForm'
import RecentSalesForm from '../forms/RecentSaleForm'
import TestimonialForm from '../forms/TestimonialForm'
import AreYouSure from '../dialog/AreYouSure'
import { UserContext } from '../provider/UserProvider'

export const ContentManagementSystemContext = React.createContext();

export default ({ entityType, Component, slidesToShow, responsiveBreakpoints }) => {
    const [ entities, setEntities ] = useState([])

    const updateEntites = querySnapshot => {
        setEntities(
            querySnapshot.docs.map(doc =>
                ({ id: doc.id, ...doc.data() })
            )
        )
    };

    let unSubscribeFromEntitySubscription = undefined;

    useEffect(() => {
        unSubscribeFromEntitySubscription = unsubFromEntitySubscription(entityType, updateEntites)

        return unSubscribeFromEntitySubscription
    }, []);

    return (
        <ContentManagementSystemContext.Provider value={entities}>
            <ContentManagementCarousel entities={entities} entityType={entityType} Component={Component} slidesToShow={slidesToShow} responsiveBreakpoints={responsiveBreakpoints} />
        </ContentManagementSystemContext.Provider>
    )
};

const ContentManagementCarousel = ({ entities, entityType, Component, slidesToShow = 1, responsiveBreakpoints = [] }) => {
    const { showDialog } = useContext(DialogContext);
    const { user } = useContext(UserContext);

    const forms = {
        employees: EmployeeForm,
        propertiesForLease: PropertiesForLeaseForm,
        recentSales: RecentSalesForm,
        testimonials: TestimonialForm
    }

    const settings = {
        arrows: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: slidesToShow,
        responsive: responsiveBreakpoints
    };

    const createDialogProps = { Component: forms[entityType], submissionFunction: payload => createEntity(entityType, payload) };
    const addButton = <button className='button cms-button' onClick={() => showDialog(DIALOG.DEFAULT, createDialogProps)}>Add</button>;

    if (entities.length === 0) return user ? addButton : null;

    return (
        <div className="cms-carousel">
            {user ? addButton : null}
        {(
            <Slider {...settings}>
                {entities.map(entity => {
                    const updateDialogProps = { Component: forms[entityType], currentValues: entity, submissionFunction: payload => updateEntity(entityType, entity.id, payload) }
                    const deleteDialogProps = { Component: AreYouSure, submissionFunction: () => deleteEntity(entityType, entity.id, entity.fullPath) }
                    
                    return (
                        <EntityWrapper user={user} key={entity.id} entity={entity} entityType={entityType} Component={Component} updateDialogProps={updateDialogProps} deleteDialogProps={deleteDialogProps} />
                    );
                })}
            </Slider>
        )}
        </div>
    );
};

const EntityWrapper = ({ user, entity, entityType, Component, updateDialogProps, deleteDialogProps }) => {
    const { showDialog } = useContext(DialogContext);

    return (
        <div className={`cms-entity-${entityType}`}>
            <Component {...entity} />
            {user ? (
                <>
                    <button className='button cms-button' onClick={() => showDialog(DIALOG.DEFAULT, updateDialogProps)}>Edit</button>
                    <button className='button cms-button' onClick={() => showDialog(DIALOG.DEFAULT, deleteDialogProps)}>Delete</button>
                </>
            ) : null
            }
        </div>
    );
};