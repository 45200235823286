import React from 'react'
import Footer from '../Footer'
import Navigation from '../navigation'

const Page = ({ children, pageClassName, hideNavBar, ...rest }) => (
    <div className="page">
        <Navigation />
        {children}
        <Footer />
    </div>
)

export default Page