import React, { useState } from 'react'

const AreYouSure = ({submissionFunction, onClose}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')

    const handleDelete = async () => {
        try {
            setIsDeleting(true)
            await submissionFunction();
            setIsDeleting(false)
            setSuccess(true)
            setError('')
        } catch ({ message }) {
            setIsDeleting(false)
            setError(message);
        }
    }

    return (
        success ? (
            <h2>Deleted Successfully</h2>
        ) : (
            <>
                <h3 className="ShareButton-header">Are your sure?</h3>
                <button className='button' onClick={handleDelete} disabled={isDeleting}>{isDeleting ? 'Deleting...' : 'Delete'}</button>
                <button className='button' onClick={onClose}>Cancel</button>
                <p>{error}</p>
            </>
        )
    )
}

export default AreYouSure