import React, { useState } from 'react'
import X from '../icon/X';
import AuthenticationLinks from './AuthenticationLinks';
import Hamburger from '../icon/Hamburger';

const MobileHamburger = ({ links, scrollTo }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open)
    }

    const onClick = (elementId) => {
        scrollTo(elementId)
        toggleOpen()
    }

    return (
        <div className="mobile-nav">
            <button className='mobile-nav-button' onClick={toggleOpen}>
                <Hamburger />
            </button>
            {open && (
                <div className='mobile-nav-menu'>
                    <button className='mobile-nav-links__close-button' onClick={toggleOpen}><X fill={'var(--secondary-color)'} /></button>
                    <ul className="mobile-nav-links">
                        {links.map(link => (
                            <li key={link.label}>
                                <button className="nav-link" onClick={() => onClick(link.url)}>{link.label}</button>
                            </li>
                        ))}
                        <li><AuthenticationLinks onClick={toggleOpen} /></li>
                    </ul>
                </div>
            )}
        </div>
    )
};

export default MobileHamburger;