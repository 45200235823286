import React, { useContext } from 'react'
import Page from "."
import { DialogContext } from '../dialog'
import homeImg from '../../img/home-img.jpg'
import KWS from '../../img/KWS.png'
import '../../styles/Home.css'
import ContentManagementSystemProvider from '../provider/ContentManagementSystemProvider'
import Property from '../Propterty'
import Testimonial from '../Testimonial'
import { DIALOG } from '../../utils/constants'
import { useLocation } from 'react-router-dom'

export default () => {
    const { showDialog } = useContext(DialogContext);
    const userManagementParams = new URLSearchParams(useLocation().search)
    const userManagementMode = userManagementParams.get('mode')
    if (userManagementMode) {
        showDialog(DIALOG.HANDLE_PASSOWRD_RESET, { userManagementParams })
    }

    const propertyslickResponsiveness = [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 750,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]

    return (
        <Page pageClassName="Home">
            <div className='landing-page' id="welcome-page">
                <div className='welcome'>
                    <h1>Gaining Financial Independence through Real Estate</h1>
                    <p>Morris Real Estate Solutions or MRES is a real estate investment company that is focused on investing in Pittsburgh and its surrounding communities. The Morris' believe in providing the residents of Pittsburgh with safe and affordable housing, which will result in strengthening the community.</p>
                    <button className='button welcome-button' onClick={() => showDialog(DIALOG.CONTACT_US)}>Contact Us</button>
                </div>
                <img className="landing-img" alt="" src={homeImg} />
            </div>
            <div className="home-section" id="our-services">
                <div className='our-services-section'>
                    <h1>We Are</h1>
                    <div className='services'>
                        <div>
                            <h2>Leasing Agents</h2>
                        </div>
                        <div>
                            <h2>Real Estate Agents</h2>
                            <img alt="Keller Williams Advisors" src={KWS} />
                        </div>
                        <div>
                            <h2>Consultants</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-section" id='meet-the-team'>
                <h1>Meet the Team</h1>
                <ContentManagementSystemProvider
                    entityType={'employees'}
                    Component={({ imageSrc, description }) => (
                        <div className="employee">
                            <img alt="employee headshot" src={imageSrc} />
                            <p>{description}</p>
                        </div>
                    )}
                />
            </div>
            <div className="home-section" id='recent-sales'>
                <h1>Recent Sales</h1>
                <ContentManagementSystemProvider
                    slidesToShow={3}
                    responsiveBreakpoints={propertyslickResponsiveness}
                    entityType={'recentSales'}
                    Component={Property}
                />
            </div>
            <div className="home-section" id='properties-for-lease'>
                <h1>Properties for Lease</h1>
                <ContentManagementSystemProvider
                    slidesToShow={3}
                    responsiveBreakpoints={propertyslickResponsiveness}
                    entityType={'propertiesForLease'}
                    Component={Property}
                />
            </div>
            <div className="home-section" id='testimonials'>
                <h1>What our Clients are saying about us</h1>
                <ContentManagementSystemProvider
                    entityType={'testimonials'}
                    Component={Testimonial}
                />
            </div>
        </Page>
    )
}
