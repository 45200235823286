import React from 'react'
import '../styles/Footer.css'
import { CITY, DAYS, EMAIL, HOURS, PHONE_NUMBER, PHONE_NUMBER_VALUE, STREET_ADDRESS } from '../utils/constants'
import FacebookLogo from './icon/FacebookLogo'
import Instagram from './icon/Instagram'
import Logo from './icon/Logo'
import { scrollTo } from './navigation'

export default () => (
    <footer>
        <button className="nav-home-button" onClick={() => scrollTo('welcome-page')}>
            <Logo />
        </button>
        <p>
            Office Hours:
            <br/>{DAYS}
            <br/>{HOURS}
        </p>
        <address>
            <div>
                <p>
                    {STREET_ADDRESS}<br/>
                    {CITY}
                </p>
                <a href={`tel:${PHONE_NUMBER_VALUE}`}>{PHONE_NUMBER}</a><br/>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a><br/>
            </div>
        </address>
        <div className='social-links'>
            <a className='social-link' href="https://www.instagram.com/morrisrealestatesolutions">
                <Instagram />
            </a>
            <a className='social-link' href="https://www.facebook.com/MorrisRealEstateSolutions">
                <FacebookLogo />
            </a>
        </div>
    </footer>
)