import React, { useState, useEffect, createRef } from 'react'
import dialogPolyfill from 'dialog-polyfill'
import '../../styles/Dialog.css'
import { DIALOG } from '../../utils/constants'
import ShareDialog from './ShareDialog'
import ContactUsDialog from './ContactUsDialog'
import X from '../icon/X'
import Login from '../../component/page/Login'
import PasswordReset from '../page/PasswordReset'
import ResetPassword from '../page/ResetPassword'

export const DialogContext = React.createContext()

const DialogContent = ({ type, onClose, dialogProps }) => {
    switch (type) {
        case DIALOG.LOG_IN:
            return <Login onClose={onClose} />
        case DIALOG.SHARE:
            return <ShareDialog onClose={onClose} {...dialogProps} />
        case DIALOG.CONTACT_US:
            return <ContactUsDialog {...dialogProps} />
        case DIALOG.PASSWORD_RESET:
            return <ResetPassword />
        case DIALOG.HANDLE_PASSOWRD_RESET:
            return <PasswordReset />
        case DIALOG.DEFAULT:
            return <dialogProps.Component {...dialogProps} onClose={onClose} />
        default:
            return <></>
    }
}

const Dialog = ({ children }) => {
    const [ showing, setShowing ] = useState(false)
    const [ type, setType ] = useState('')
    const [ dialogProps, setDialogProps ] = useState(null)
    const dialogRef = createRef()

    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close()
        }
        setShowing(false)
    }

    const showDialog = (dialogContentType, dialogProps) => {
        setType(dialogContentType)
        setDialogProps(dialogProps)
        setShowing(true)
    }

    const onClose = () => {
        closeDialog()
    }

    const closeIfEscapeKeyIsPressed = e => {
        if(e.keyCode === 27) {  // if ESC key is pressed
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', closeIfEscapeKeyIsPressed)
        return () => {
            document.removeEventListener('keydown', closeIfEscapeKeyIsPressed)
        }
    }, [])

    useEffect(() => {
        if (showing) {
            dialogPolyfill.registerDialog(dialogRef.current)
            dialogRef.current.showModal()
        }
    }, [ showing ])

    return (
        <DialogContext.Provider value={{ showDialog }}>
            {showing && (
                <dialog ref={dialogRef}>
                    <button className="dialog-close-button" onClick={onClose}><X /></button>
                    <DialogContent type={type} onClose={onClose} dialogProps={dialogProps} />
                </dialog>
            )}
            {children}
        </DialogContext.Provider>
    )
}

export default Dialog