import React, { useContext } from 'react'
import '../../styles/Navigation.css'
import AuthenticationLinks from './AuthenticationLinks'
import Logo from '../icon/Logo'
import { DIALOG } from '../../utils/constants'
import { DialogContext } from '../dialog'
import MobileHamburger from './MobileHamburger'

export const scrollTo = elementId => {
    var element = document.getElementById(elementId);
    var scrollOffset = document.getElementById('navigation').getBoundingClientRect().bottom + 10;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - scrollOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

const Navigation = () => {
    const { showDialog } = useContext(DialogContext);

    const links = [
        {
            label: "Meet the Team",
            url: "meet-the-team"
        },
        {
            label: "Our Services",
            url: "our-services"
        },
        {
            label: "Recent Sales",
            url: "recent-sales"
        },
        {
            label: "Properties for Lease",
            url: "properties-for-lease"
        },
        {
            label: "Testimonials",
            url: "testimonials"
        }
    ];

    return (
        <nav className="nav" id="navigation">
            <div className='desktop-left-content'>
                <button className="nav-home-button" onClick={() => scrollTo('welcome-page')}>
                    <Logo />
                </button>
                <ul className='desktop-nav-links'>
                    {links.map(link => (
                        <li key={link.url} className="nav-link">
                            <button className='nav-link' href={link.url} onClick={() => scrollTo(link.url)}>{link.label}</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='desktop-right-content'>
                <AuthenticationLinks />
                <button className="button nav-contact-us-button" onClick={() => showDialog(DIALOG.CONTACT_US)}>Contact Us</button>
                <MobileHamburger links={links} scrollTo={scrollTo} />
            </div>
        </nav>
    );
};

export default Navigation;