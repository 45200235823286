import React from 'react'

export default () => (
    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.8682 14.7974C26.2869 14.7974 25.8993 15.1663 25.8993 15.7198V18.487H22.9923C22.4109 18.487 22.0233 18.856 22.0233 19.4094C22.0233 19.9628 22.4109 20.3318 22.9923 20.3318H26.8682C27.4496 20.3318 27.8372 19.9628 27.8372 19.4094V15.7198C27.8372 15.1663 27.4496 14.7974 26.8682 14.7974Z" fill="black"/>
        <path d="M17.1783 18.4869H11.3643C10.783 18.4869 10.3954 18.8559 10.3954 19.4093C10.3954 19.9628 10.783 20.3318 11.3643 20.3318H17.1783C17.7597 20.3318 18.1473 19.9628 18.1473 19.4093C18.1473 18.8559 17.7597 18.4869 17.1783 18.4869Z" fill="black"/>
        <path d="M5.55041 18.487H2.64343V15.7198C2.64343 15.1663 2.25583 14.7974 1.67444 14.7974C1.09304 14.7974 0.705444 15.1663 0.705444 15.7198V19.4094C0.705444 19.9628 1.09304 20.3318 1.67444 20.3318H5.55041C6.1318 20.3318 6.5194 19.9628 6.5194 19.4094C6.5194 18.856 6.1318 18.487 5.55041 18.487Z" fill="black"/>
        <path d="M1.67444 12.9525C2.25583 12.9525 2.64343 12.5836 2.64343 12.0301V8.3405C2.64343 7.78705 2.25583 7.41809 1.67444 7.41809C1.09304 7.41809 0.705444 7.78705 0.705444 8.3405V12.0301C0.705444 12.5836 1.09304 12.9525 1.67444 12.9525Z" fill="black"/>
        <path d="M5.55041 0.0388184H1.67444C1.09304 0.0388184 0.705444 0.407781 0.705444 0.961226V4.65086C0.705444 5.2043 1.09304 5.57326 1.67444 5.57326C2.25583 5.57326 2.64343 5.2043 2.64343 4.65086V1.88363H5.55041C6.1318 1.88363 6.5194 1.51467 6.5194 0.961226C6.5194 0.407781 6.1318 0.0388184 5.55041 0.0388184Z" fill="black"/>
        <path d="M17.1783 0.0388184H11.3643C10.783 0.0388184 10.3954 0.407781 10.3954 0.961226C10.3954 1.51467 10.783 1.88363 11.3643 1.88363H17.1783C17.7597 1.88363 18.1473 1.51467 18.1473 0.961226C18.1473 0.407781 17.7597 0.0388184 17.1783 0.0388184Z" fill="black"/>
        <path d="M26.8682 0.0388184H22.9923C22.4109 0.0388184 22.0233 0.407781 22.0233 0.961226C22.0233 1.51467 22.4109 1.88363 22.9923 1.88363H25.8993V4.65086C25.8993 5.2043 26.2869 5.57326 26.8682 5.57326C27.4496 5.57326 27.8372 5.2043 27.8372 4.65086V0.961226C27.8372 0.407781 27.4496 0.0388184 26.8682 0.0388184Z" fill="black"/>
        <path d="M26.8682 7.41809C26.2868 7.41809 25.8992 7.78705 25.8992 8.3405V12.0301C25.8992 12.5836 26.2868 12.9525 26.8682 12.9525C27.4496 12.9525 27.8372 12.5836 27.8372 12.0301V8.3405C27.8372 7.78705 27.4496 7.41809 26.8682 7.41809Z" fill="black"/>
    </svg>
);