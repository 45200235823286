import React, { useState } from 'react'
import { createEntity } from '../../firebase';
import Form from '../form'
import { EmailField, SelectField, TextField } from '../form/input';
import Instagram from '../icon/Instagram'
import FacebookLogo from '../icon/FacebookLogo'
import { CITY, DAYS, EMAIL, HOURS, PHONE_NUMBER, PHONE_NUMBER_VALUE, STREET_ADDRESS } from '../../utils/constants';

export default () => {
    const [subject, setSubject] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [postSuccess, setPostSuccess] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [postingError, setPostingError] = useState('')

    const buildEmail = () => (
        {
            to: EMAIL,
            message: {
              subject: `Contact Request: ${subject}`,
              html: `
              <div>
                <h1>Contact Request</h1>
                <p>Subject: ${subject}</p>
                <p>Message: ${message}</p>
                <p>Phone Number: ${phoneNumber}</p>
                <p>Email: ${email}</p>
              </div>
              `,
            },
        }
    )

    const subjectOptions = [
        { value: 'Consultation Inquiries', text: 'Consultation Inquiries' },
        { value: 'Real Estate Agent Inquires', text: 'Real Estate Agent Inquires' }
    ]

    const onSubmit = () => {
        setIsPosting(true)
        createEntity('mail', buildEmail()).then(() => {
            setPostSuccess(true)
            setIsPosting(false)
        }).catch(e => setPostingError(e))
    }

    return postSuccess ? (
        <div className='contact-us-success-modal'>
            <h2>Thank You!</h2>
            <p>We’ll get back to you as soon as possible!</p>
            <p>Be sure to follow us on social media!</p>
            <div className='social-links'>
                <a className='social-link' href="https://www.instagram.com/morrisrealestatesolutions">
                    <Instagram fill="var(--primary-color)" />
                </a>
                <a className='social-link' href="https://www.facebook.com/MorrisRealEstateSolutions">
                    <FacebookLogo fill="var(--primary-color)" />
                </a>
            </div>
        </div>
    ) : (
        <div className='contact-us-modal'>
            <h2>Contact Us</h2>
            <p>
                Are you looking for affordable housing in the Pittsburgh area? Contact Us Now for more information on available listings!
            </p>
            <address>
                <p>
                    Office Hours:
                    <br/>{DAYS}
                    <br/>{HOURS}
                </p>
                <p>
                    {STREET_ADDRESS}<br/>
                    {CITY}
                </p>
                <a className='contact-link' href={`tel:${PHONE_NUMBER_VALUE}`}>{PHONE_NUMBER}</a><br/>
                <a className='contact-link' href={`mailto:${EMAIL}`}>{EMAIL}</a><br/>
            </address>
            <Form submitValue='Submit' submittingValue="Sending your message..." onSubmit={onSubmit} submitting={isPosting} errorMessage={postingError}>
                <SelectField required errorMessage='Please select an option' options={subjectOptions} id='subject-field' placeholder='Subject' valueHook={setSubject} />
                <TextField id='phone-number-field' type='tel' placeholder='Phone Number' valueHook={setPhoneNumber} />
                <EmailField required valueHook={setEmail} />
                <TextField required errorMessage='Please provide a message' id='message-field' type='textarea' placeholder='Message' valueHook={setMessage} />
            </Form>
        </div>
    )
}