import React from 'react'
import '../styles/Property.css'
import Bedroom from './icon/Bedroom'
import LivingArea from './icon/LivingArea'
import Bathroom from './icon/Bathroom'

const Property = ({ imageSrc, monthlyRent, price, address, bedroomCount, bathroomCount, livingArea }) => (
    <div className='property'>
        <img alt={address} src={imageSrc} />
        <div className="property-info">
            {monthlyRent ? (<h2>{monthlyRent} USD/month</h2>) : (<h2>{price} USD</h2>)}
            <small>{address}</small>
            <hr/>
            <div className='property-stats'>
                <div className='property-stat'>
                    <div>
                        <Bedroom />
                        <h3>{bedroomCount}</h3>
                    </div>
                    <small>Bedrooms</small>
                </div>
                <div className='property-stat'>
                    <div>
                        <Bathroom />
                        <h3>{bathroomCount}</h3>
                    </div>
                    <small>Bathrooms</small>
                </div>
                <div className='property-stat'>
                    <div>
                        <LivingArea />
                        <h3>{livingArea} ft&#178;</h3>
                    </div>
                    <small>Living Area</small>
                </div>
            </div>
        </div>
    </div>
)

export default Property;