export const COMPANY_EMAIL = "thepivotfund@gmail.com";

export const PATHS = {
  HOME: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  USER_MANAGEMENT: "/usermgmt",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT: "/account",
  DONATE: "/donate",
  FEEDBACK: "/feedback",
  DONATIONS: "/donations",
  EMAIL: `mailto:${COMPANY_EMAIL}`,
  PROFILE: `/profile`
};

export const PAGE_TITLES = {
  "/": "Home",
  "/login": "Log In",
  "/sign-up": "Sign In",
  "/about-us": "About Us",
  "/usermgmt": "User Management",
  "/reset-password": "Reset Password",
  "/account": "Account",
  "/donate": "Donate",
  "/feedback": "Feedback",
  "/donations": "Donations"
};

export const THEMES = {
  DARK: "dark",
  LIGHT: "light"
};

export const NOTIFICATION_PERMISSION_STATUS = {
  GRANTED: "granted",
  DENIED: "denied",
  DEFAULT: "default"
};

export const DIALOG = {
  SHARE: "share",
  LOG_IN: "log_in",
  CONTACT_US: "contact_us",
  CREATE_ENTITY: "create_entity",
  UPDATE_ENTITY: "update_entity",
  DELETE_ENTITY: "delete_entity",
  DEFAULT: "default",
  PASSWORD_RESET: "password_reset",
  HANDLE_PASSOWRD_RESET: "handle_password_reset"
};

export const ACCEPTABLE_FILE_INPUT_TYPES = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon"
];

export const BASE_URL = "https://pivotfund.org";

export const FULL_URL = path => `${BASE_URL}${path}`;

export const PHONE_NUMBER = "(412) 770-2647";

export const PHONE_NUMBER_VALUE = "+14127702647";

export const STREET_ADDRESS = "2608 Brownsville Road";

export const CITY = "Pittsburgh PA 15227";

export const EMAIL = "info@morrisres.com";

export const DAYS = "Monday - Friday";

export const HOURS = "8:00AM - 4:00PM";
