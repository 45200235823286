import React from 'react'
import '../styles/App.css'
import { Switch, Route } from 'react-router-dom'
import Home from './page/Home'
import UserProvider from './provider/UserProvider'
import { PATHS } from '../utils/constants'
import Dialog from './dialog'

const App = () => (
    <Dialog>
        {/* <CampaignProvider> */}
            <UserProvider>
                {/* <UserContext.Consumer> */}
                    {/* {({ user }) => ( */}
                        <Switch>
                            <Route exact path={PATHS.HOME}>
                                <Home />
                            </Route>
                            {/* <Route exact path={PATHS.LOGIN}>
                                <Login user={user} />
                            </Route>
                            <Route exact path={PATHS.SIGN_UP}>
                                <Login user={user} />
                            </Route> */}
                            {/* <ProfileRoute exact path={`${PATHS.PROFILE}/:doneeId`} Component={Profile} /> */}
                            {/* <UserManagementRoute path={PATHS.USER_MANAGEMENT} /> */}
                            {/* <ProtectedRoute
                                path={PATHS.RESET_PASSWORD}
                                condition={!user}
                                Component={ResetPassword}
                            /> */}
                            {/* <ProtectedRoute
                                path={PATHS.ACCOUNT}
                                condition={user}
                                user={user}
                                Component={Account}
                            />
                            <ProtectedRoute
                                path={PATHS.DONATIONS}
                                condition={user}
                                user={user}
                                Component={Donations}
                            /> */}
                        </Switch>
                    {/* )} */}
                {/* </UserContext.Consumer> */}
            </UserProvider>
        {/* </CampaignProvider> */}
    </Dialog>
)

export default App
