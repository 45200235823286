import React from 'react'
import '../styles/Testimonial.css'

const Testimonial = ({ quote, author, description }) => {
    return (
        <div className='testimonial'>
            <blockquote>
                {quote}
            </blockquote>
            <p className='author'>{author}</p>
            <p className='title'>{description}</p>
        </div>
    );
}

export default Testimonial;