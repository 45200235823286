import React, { useState } from 'react'
import Form from '../form'
import { TextField } from '../form/input';

const TestimonialForm = ({ submissionFunction, currentValues = {}, ...props }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSucess] = useState(false)
    const [postingError, setPostingError ] = useState('')
    const [quote, setQuote] = useState(currentValues.quote || '');
    const [author, setAuthor] = useState(currentValues.author || '');
    const [description, setDescription] = useState(currentValues.description || '');

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            await submissionFunction({ quote, author, description })
            setSucess(true);
        } catch ({ message }) {
            setPostingError(message)
        }
    };

    return (
        success ? (
            <h1>Processed Successfully</h1>
        ) : (
            <Form submitValue={Object.keys(currentValues).length ? 'Update' : 'Create'} onSubmit={handleSubmit} submitting={isSubmitting} errorMessage={postingError} submittingValue={'Processing...'}>
                <TextField id='quote' initialValue={quote} placeholder='Quote' valueHook={setQuote} />
                <TextField id='author' initialValue={author} placeholder='Author name' valueHook={setAuthor} />
                <TextField id='description' initialValue={description} placeholder='Additional info' valueHook={setDescription} />
            </Form>    
        )
    );
}

export default TestimonialForm;