import React, { useContext, useState } from 'react'
import { signIn } from '../../firebase'
import Form from '../form'
import '../../styles/Login.css'
import { EmailField, PasswordField } from '../form/input'
import { formatAuthErrorMessage } from '../../utils/errorMessages'
import { DIALOG } from '../../utils/constants'
import { DialogContext } from '../dialog'

const Login = ({ onClose }) => {
    const [ authErrorMessage, setAuthErrorMessage ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const { showDialog } = useContext(DialogContext);

    const handleUser = type => {
        // analytics.logEvent('login', { method: type })
        setAuthErrorMessage('')
        onClose()
    }

    const handleAuthError = error => {
        const errorMessage = formatAuthErrorMessage(error)
        setIsLoading(false)
        setAuthErrorMessage(errorMessage)
    }

    const handleAuth = (authProvider, type) => {
        setIsLoading(true)
        authProvider()
            .then(() => handleUser(type))
            .catch(handleAuthError)
    }

    const genericAuth = () => handleAuth(() => signIn(email, password), 'emailSignIn')

    return (
        <div>
            <Form
                onSubmit={genericAuth}
                submitValue={'Sign In'}
                submittingValue={'Signing In...'}
                submitting={isLoading}
                errorMessage={authErrorMessage}
            >
                <EmailField valueHook={setEmail} />
                <PasswordField valueHook={setPassword} />
            </Form>
            <p className='login-links'>
                <button onClick={() => showDialog(DIALOG.PASSWORD_RESET)}>Forgot Your Password?</button>
            </p>
        </div>
    )
}

export default Login