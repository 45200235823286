import React, { useEffect, useState } from "react"

const FileInput = ({ valueHook, accept, sizeLimitInMB = Infinity.valueOf(), initialValue, required, onValid, onInValid, inputProps, ...props }) => {
    const [ value, setValue ] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const ONE_MEGABYTE_IN_BYTES = 1048576;

    const parseFileList = (fileList) => [...Array(fileList.length).keys()].map(idx => fileList[idx]);

    const isInvalidFileType = (file) => accept && !accept.some(fileType => fileType === file.type)

    const someFileTypesAreInvalid = value.some(file => isInvalidFileType(file));
    const someFilesAreTooLarge = value.some(file => (sizeLimitInMB * ONE_MEGABYTE_IN_BYTES) < file.size)

    const isValid = () => {
        if (value.length === 0) {
            setErrorMessage('')
            return !required
        }

        if (someFileTypesAreInvalid) {
            setErrorMessage('Some files are not supported')
        } else if (someFilesAreTooLarge) {
            setErrorMessage(`Some files are larger than ${sizeLimitInMB}MB`)
        } else {
            setErrorMessage('')
        }
        return !(someFileTypesAreInvalid || someFilesAreTooLarge)
    }

    useEffect(() => {
        valueHook(value)
        isValid() ? onValid() : onInValid()
    }, [ value ])

    return (
        <>
            <input type='file' {...inputProps} {...props} accept={accept.join(',')} onBlur={() => props.onBlur(isValid())} onChange={event => { setValue(parseFileList(event.target.files)); }} />
            {(!!initialValue && !value.length) && (<img alt="current" src={initialValue} />)}
            {(value).map(file => (
                <div key={file.name}>
                    <small className="preview-error">{errorMessage}</small>
                    {(!!value.length) && (
                        <div className="preview">
                            <img className="preview-img" alt="preview" src={URL.createObjectURL(file)} />
                            <br/>
                            <small>Name: {file.name}</small><br />
                            <small>Type: {file.type}</small><br />
                            <small>Size: {(file.size / ONE_MEGABYTE_IN_BYTES).toFixed(2)}MB</small>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}

export default FileInput;