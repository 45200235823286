import React, { useContext } from 'react'
import { UserContext } from '../provider/UserProvider'
import '../../styles/AuthenticationLinks.css'
import { DIALOG } from '../../utils/constants'
import { auth } from '../../firebase'
import { DialogContext } from '../dialog'

const AuthenticationLinks = ({ onClick = () => {} }) => {
    const { user } = useContext(UserContext);
    const { showDialog } = useContext(DialogContext)

    return (
        <>
            {user
                ? <button className="account-link" onClick={() => { auth.signOut(); onClick() }}>Log Out</button>
                : <button className="account-link" onClick={() => { showDialog(DIALOG.LOG_IN); onClick() }}>Log In</button>
            }
        </>
    )
}

export default AuthenticationLinks