import React, { useState } from 'react'
import { ACCEPTABLE_FILE_INPUT_TYPES } from '../../utils/constants';
import Form from '../form'
import { FileField, TextField } from '../form/input';

const EmployeeForm = ({ submissionFunction, currentValues = {} }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSucess] = useState(false)
    const [images, setImages] = useState(currentValues.imageSrc || '');
    const [name, setName] = useState(currentValues.name || '');
    const [postingError, setPostingError ] = useState('')
    const [description, setDescription] = useState(currentValues.description || '');

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            await submissionFunction({ images, description, name, imageSrc: currentValues.imageSrc, fullPath: currentValues.fullPath })
            setSucess(true);
        } catch ({ message }) {
            setPostingError(message)
        }
    };

    const alreadyExists = Object.keys(currentValues).length > 0;

    return (
        success ? (
            <h1>Processed Successfully</h1>
        ) : (
            <Form submitValue={alreadyExists ? 'Update' : 'Create'} onSubmit={handleSubmit} submitting={isSubmitting} errorMessage={postingError} submittingValue={'Processing...'}>
                <FileField required={!alreadyExists} id='thumbnail' valueHook={setImages} accept={ACCEPTABLE_FILE_INPUT_TYPES} sizeLimitInMB={2} />
                <TextField required id='name' placeholder='Name' valueHook={setName} initialValue={currentValues.name} />
                <TextField required id='textarea' placeholder='Description' valueHook={setDescription} initialValue={currentValues.description} />
            </Form>    
        )
    );
}

export default EmployeeForm;